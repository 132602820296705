import PropTypes from 'prop-types'
import React from 'react'
import Loader from '../../Loader'

const MoreButton = ({ isLoading, loadMoreProducts, pagination, t }) => {
  const isDisabled = isLoading || pagination?.page === pagination?.total_pages

  return (
    <button
      className="moreButton buttonComponent--secondary"
      disabled={isDisabled}
      onClick={() => {
        loadMoreProducts(pagination?.page + 1)
      }}
    >
      {t['tcred.collections.load_more']}
      {isLoading ? (
        <div
          className="loaderWrapper"
          style={{
            left: '90%',
            position: 'absolute',
            top: '50%',
          }}
        >
          <Loader />
        </div>
      ) : null}
    </button>
  )
}

export default MoreButton

MoreButton.propTypes = {
  isLoading: PropTypes.bool,
  loadMoreProducts: PropTypes.func,
  pagination: PropTypes.object,
  t: PropTypes.object,
}
