import PropTypes from 'prop-types'
import React from 'react'

const Viewing = ({ displayedItemsNumber, pagination, t }) => {
  const currentCount = displayedItemsNumber || 0
  const overallCount = pagination && pagination.total_count ? pagination.total_count : 0

  const viewingTranslation = t['tcred.collections.viewing']
    .replace('%{current_count}', currentCount)
    .replace('%{overall_count}', overallCount)

  return (
    <div>
      {viewingTranslation}
    </div>
  )
}

export default Viewing

Viewing.propTypes = {
  displayedItemsNumber: PropTypes.number,
  pagination: PropTypes.shape({
    total_count: PropTypes.number,
  }),
  t: PropTypes.object,
}
