import { PropTypes } from 'prop-types'
import React from 'react'

const Loader = ({ isLarge }) => {

  const largeSpinner = {
    height: '5rem',
    width: '5rem',
  }

  return (
    <div className="loader">
      {isLarge ?
        <div className="loader_spinner" style={largeSpinner} />
        :
        <div className="loader_spinner" />
      }
    </div>
  )
}

export default Loader

Loader.propTypes = {
  isLarge: PropTypes.bool,
}
