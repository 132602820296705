import { OpeningHours } from '@tc-packages/tc-common'
import PropTypes from 'prop-types'
import React from 'react'

export default class OpeningHoursWrapper extends React.Component {
  render() {
    const {
      additionalClass,
      enabled,
      inputID,
      preloadData,
      t,
      timeFormat24hours,
      weekDays,
    } = this.props

    return (
      <OpeningHours
        additionalClass={additionalClass}
        enabled={enabled}
        inputID={inputID}
        preloadData={preloadData}
        t={t}
        timeFormat24hours={timeFormat24hours}
        weekDays={weekDays}
      />
    )
  }
}

OpeningHoursWrapper.propTypes = {
  additionalClass: PropTypes.string,
  enabled: PropTypes.bool,
  inputID: PropTypes.string,
  preloadData: PropTypes.object,
  t: PropTypes.any,
  timeFormat24hours: PropTypes.bool,
  weekDays: PropTypes.array,
}
