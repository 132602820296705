import { OpeningHoursViewer } from '@tc-packages/tc-common'
import PropTypes from 'prop-types'
import React from 'react'

export default class OpeningHoursViewerWrapper extends React.Component {
  render() {
    const { preloadData, t, timeFormat24hours, weekDays } = this.props

    return (
      <OpeningHoursViewer
        preloadData={preloadData}
        t={t}
        timeFormat24hours={timeFormat24hours}
        weekDays={weekDays}
      />
    )
  }
}

OpeningHoursViewerWrapper.propTypes = {
  preloadData: PropTypes.object,
  t: PropTypes.any,
  timeFormat24hours: PropTypes.bool,
  weekDays: PropTypes.array,
}
