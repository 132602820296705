import { useState } from 'react'
import axios from 'axios'

const useApiRequest = () => {
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null)

  const getCsrfToken = () =>
    document.querySelector('meta[name="csrf-token"]').getAttribute('content')

  const sendRequest = async (
    url,
    method = 'GET',
    data = null,
    headers = {},
  ) => {
    setIsLoading(true)
    setError(null)

    try {
      const response = await axios({
        method,
        url,
        data,
        headers: {
          'Content-Type': 'application/json',
          'X-CSRF-Token': getCsrfToken(),
          'X-Requested-With': 'XMLHttpRequest',
          ...headers,
        },
      })
      setIsLoading(false)
      return response.data
    } catch (err) {
      setIsLoading(false)
      setError(err)
      throw err
    }
  }

  return { isLoading, error, sendRequest }
}

export default useApiRequest
