import PropTypes from 'prop-types'
import React from 'react'
import { deleteCategoriesQueryParams } from '../QueryParamsActions'

const ActiveFilters = ({
  activeFilters,
  checkBoxFilters,
  setAvailabilityTitle,
  setAvailabilityValue,
  setCategoryTitle,
  setCategoryValue,
  setIsAvailabilityParamsRemoved,
  setIsCategoryParamsRemoved,
  setIsKeywordParamsRemoved,
  setIsPriceParamsRemoved,
  setKeyword,
  setPriceTitle,
  setPriceValue,
  isLoading,
}) => {
  const removeItem = (arr, item) => {
    return arr.filter((f) => f !== item)
  }

  return (
    <div className="collectionFilter_filter-tags">
      <ul className="collectionFilter_filter-tags-list">
        {activeFilters.map((item) => {
          if (
            item.title !== '' &&
            item.title.length > 0 &&
            item.type !== 'categories[]'
          ) {
            return (
              <li
                className="collectionFilter_filter-tags-item"
                key={Math.random()}
              >
                <span>{item.title}</span>
                <button
                  className="collectionFilter_filter-tags-remove"
                  disabled={isLoading}
                  onClick={() => {
                    if (!isLoading) {
                      if (item.type === 'availabilities[]') {
                        setIsAvailabilityParamsRemoved(true)
                        setAvailabilityTitle('')
                        setAvailabilityValue('')
                      }
                      if (item.type === 'keyword') {
                        setIsKeywordParamsRemoved(true)
                        setKeyword('')
                      }
                      if (item.type === 'price') {
                        setIsPriceParamsRemoved(true)
                        setPriceTitle('')
                        setPriceValue('')
                      }
                    }
                  }}
                >
                  <div className="plusIcon">+</div>
                </button>
              </li>
            )
          } else if (item.type === 'categories[]') {
            return item.title.map((filterName) => {
              return (
                <li
                  className="collectionFilter_filter-tags-item"
                  key={Math.random()}
                >
                  <span>{filterName}</span>
                  <button
                    className="collectionFilter_filter-tags-remove"
                    disabled={isLoading}
                    onClick={() => {
                      if (!isLoading) {
                        const removedFilter = checkBoxFilters.filters.find(
                          (item) => item.title === filterName,
                        )
                        deleteCategoriesQueryParams(true, removedFilter?.value)
                        setCategoryTitle(
                          removeItem(activeFilters[1].title, filterName),
                        )
                        setCategoryValue(
                          removeItem(
                            activeFilters[1].value,
                            removedFilter?.value,
                          ),
                        )
                      }
                    }}
                  >
                    <div className="plusIcon">+</div>
                  </button>
                </li>
              )
            })
          }
        })}
        {activeFilters.filter(
          (item) =>
            (item.title !== '' &&
              item.title.length > 0 &&
              item.type !== 'categories[]') ||
            (item.type === 'categories[]' && item.title.length !== 0),
        ).length ? (
            <button
              className="collectionFilter_filter-tags-clear"
              disabled={isLoading}
              onClick={() => {
                if (!isLoading) {
                  setAvailabilityTitle('')
                  setAvailabilityValue([])
                  setCategoryTitle([])
                  setCategoryValue([])
                  setIsAvailabilityParamsRemoved(true)
                  setIsCategoryParamsRemoved(true)
                  setIsKeywordParamsRemoved(true)
                  setIsPriceParamsRemoved(true)
                  setKeyword('')
                  setPriceTitle('')
                  setPriceValue('')
                }
              }}
            >
            Clear Filters
            </button>
          ) : null}
      </ul>
    </div>
  )
}

export default ActiveFilters

ActiveFilters.propTypes = {
  activeFilters: PropTypes.array,
  checkBoxFilters: PropTypes.object,
  isLoading: PropTypes.bool,
  setAvailabilityTitle: PropTypes.func,
  setAvailabilityValue: PropTypes.func,
  setCategoryTitle: PropTypes.func,
  setCategoryValue: PropTypes.func,
  setIsAvailabilityParamsRemoved: PropTypes.func,
  setIsCategoryParamsRemoved: PropTypes.func,
  setIsKeywordParamsRemoved: PropTypes.func,
  setIsPriceParamsRemoved: PropTypes.func,
  setKeyword: PropTypes.func,
  setPriceTitle: PropTypes.func,
  setPriceValue: PropTypes.func,
}
