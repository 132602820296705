import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { InputBase, IconButton, CircularProgress } from '@mui/material'
import { CloseOutlined } from '@mui/icons-material'
import RedeemCharity from './RedeemCharity'

const CharitiesSearch = ({
  availablePointsPath,
  charitiesPath,
  currentUser,
  newUserSessionPath,
  redeemCharityPath,
  newCharityPath,
  userCanCreateCharity,
  updateSaveOrg,
  t
}) => {
  const [approvedCharities, setApprovedCharities] = useState([])
  const [charitiesCount, setCharitiesCount] = useState(0)
  const [loading, setLoading] = useState(false)
  const [getResults, setGetResults] = useState(false)
  const [searchParam, setSearchParam] = useState('')
  const [inputValue, setInputValue] = useState('')
  const props = {
    availablePointsPath,
    charitiesPath,
    currentUser,
    newCharityPath,
    newUserSessionPath,
    redeemCharityPath,
    t,
    updateSaveOrg,
    userCanCreateCharity
  }

  const loadJSON = async (event) => {
    event.preventDefault()
    const search = document.getElementById('search').value
    setLoading(true)
    try {
      const response = await fetch(`${charitiesPath}?search=${encodeURIComponent(search)}`)
      const data = await response.json()
      setApprovedCharities(data.approved_charities)
      setCharitiesCount(data.approved_charities.length)
      setGetResults(true)
    } catch (error) {
      window.alert(t['tcred.charities.fetch_failed'])
    } finally {
      setLoading(false)
    }
    setSearchParam(search)
  }

  const renderBottomInfo = () => (
    <div className="text-center px-2">
      {t['charities.charity_not_found_add_new']}
      <a href={t['charities.learn_more_link']}><b> {t['charities.learn_more']}</b></a>
      <br /><br />
      <a className="buttonComponent--primary" href={newCharityPath}>{t['charities.add_new_charity']}</a>
    </div>
  )

  const mapCharities = () => (
    <React.Fragment>
      {approvedCharities.map(charity => {
        const locationParts = [charity.city, charity.region_code].filter(Boolean)
        const location = locationParts.join(', ')
        return (
          <tr key={charity.id}>
            <td className="d-flex d-md-table-cell flex-wrap">
              <RedeemCharity charity={charity} parentsProps={props} updateSaveOrg={updateSaveOrg} />
              {charity.name}
              {location && <span className="d-block d-md-inline fw-normal grey-text w-100"><i> {location}</i></span>}
            </td>
          </tr>
        )
      })}
    </React.Fragment>
  )

  const renderSearchResults = () => (
    <React.Fragment>
      {charitiesCount > 0 ? (
        <div className="tableComponent tableComponent_oneColumnMobile">
          <table className="tableComponent_table tableComponent_withoutHeadline">
            <tbody>{mapCharities()}</tbody>
          </table>
        </div>
      ) : (
        <p>{t['tcnext.charities.search_failed']}: <b>{searchParam}</b></p>
      )}
    </React.Fragment>
  )

  return (
    <div className="manageCharity searchCharity">
      <h3 className="manageCharity_title">{t['tcnext.charities.search_for_existing_title']}</h3>
      <div className="manageCharity_searchForm">
        <form className="charitySearchForm" onSubmit={loadJSON}>
          <div className="charitySearchForm_searchField bootstrapForm_input">
            <InputBase
              endAdornment={
                inputValue && (
                  <IconButton aria-label="clear input" className="searchClear" onClick={() => setInputValue('')}>
                    <CloseOutlined className="iconClear" />
                  </IconButton>
                )
              }
              fullWidth
              id="search"
              onChange={(e) => setInputValue(e.target.value)}
              placeholder={t['tcnext.charities.search_default_text']}
              type="text"
              value={inputValue}
            />
          </div>
          <div className="charitySearchForm_submitButton">
            <input className="buttonComponent--primary" type="submit" value={t['tcred.search']} />
          </div>
        </form>
        <div className="charitySearchForm_results manageCharity_results">
          {loading && <div className="loader"><CircularProgress size={40} /></div>}
          {getResults && renderSearchResults()}
        </div>
      </div>
      {userCanCreateCharity && renderBottomInfo()}
    </div>
  )
}

CharitiesSearch.propTypes = {
  availablePointsPath: PropTypes.string,
  charitiesPath: PropTypes.string,
  currentUser: PropTypes.object,
  newCharityPath: PropTypes.string,
  newUserSessionPath: PropTypes.string,
  redeemCharityPath: PropTypes.string,
  t: PropTypes.object,
  updateSaveOrg: PropTypes.func,
  userCanCreateCharity: PropTypes.bool
}

export default CharitiesSearch
