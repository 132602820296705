import PropTypes from 'prop-types'
import React from 'react'

const Breadcrumbs = ({ breadcrumbs }) => {
  return (
    <nav id="breadcrumbs">
      <ol className="breadcrumbComponent">
        {breadcrumbs?.map((item) => {
          return (
            <a
              className="breadcrumbComponent_link"
              href={item?.url}
              key={Math.random()}
            >
              {item.label}
            </a>
          )
        })}
      </ol>
    </nav>
  )
}

export default Breadcrumbs

Breadcrumbs.propTypes = {
  breadcrumbs: PropTypes.array,
}
