import PropTypes from 'prop-types'
import React, { useEffect, useRef, useState } from 'react'
import useOutsideAlerter from '../hooks/useOutsideClickEvent'
import { deleteCategoriesQueryParams } from './QueryParamsActions'

const CollectionFilterCheckBox = ({
  activeFilters,
  availabilityValue,
  categoryValue,
  collectionFilters,
  isBrigades,
  isLoading,
  setFilterTitle,
  setFilterValue,
  setIsParamsRemoved,
}) => {
  const wrapperRef = useRef(null)
  const [isToggled, setIsToggled] = useState(false)
  useOutsideAlerter(wrapperRef, setIsToggled)

  const collapsedPanelClass = isToggled
    ? 'collectionFilter_options collapse show'
    : 'collectionFilter_options collapse'
  const collectionFilterToggle = isToggled
    ? 'collectionFilterToggle toggle-open'
    : 'collectionFilterToggle'

  const removeItem = (arr, item) => {
    return arr.filter((f) => f !== item)
  }

  const brigadeFilters = collectionFilters?.filters.filter((item) => {
    if (item.value === 'zwb_product' || item.value === 'merch_product')
      return null
    return item
  })

  const filtersToRender = isBrigades
    ? brigadeFilters
    : collectionFilters?.filters

  useEffect(() => {
    const intersections = collectionFilters?.filters.filter(
      (e) => categoryValue.indexOf(e.value) !== -1,
    )
    const filteredTitles =
      intersections?.length > 0 ? intersections.map((item) => item.title) : []
    const filteredValues =
      intersections?.length > 0 ? intersections.map((item) => item.value) : []

    if (!availabilityValue) {
      setFilterTitle([...filteredTitles])
      setFilterValue([...filteredValues])
    }
  }, [categoryValue?.toString(), collectionFilters?.filters])

  let filterTitle = {}

  filterTitle = collectionFilters?.filters.find((item) => {
    if (item?.filters && item?.filters.length > 0) {
      return item?.filters.find(
        (item) => item.value.toString() === availabilityValue,
      )
    }
    return item.value.toString() === availabilityValue
  })

  useEffect(() => {
    if (filterTitle?.title) setFilterTitle(filterTitle?.title)
  }, [filterTitle?.title])

  const handleSelectCategory = (event) => {
    const value = event.target.value
    const name = event.target.getAttribute('name')
    const isChecked = event.target.checked
    if (isChecked) {
      // Add checked item into checkList
      setFilterTitle([name])
      setFilterValue([value])
    } else {
      // Remove unchecked item from checkList
      setFilterTitle(removeItem(activeFilters[1].title, name))
      setFilterValue(removeItem(activeFilters[1].value, value))

      deleteCategoriesQueryParams(true, value)
    }
  }

  const handleSelectAvailability = (event) => {
    const value = event.target.value
    const name = event.target.getAttribute('name')
    const isChecked = event.target.checked
    if (isChecked) {
      // Add checked item into checkList
      setFilterTitle(name)
      setFilterValue(value)
      setIsParamsRemoved(false)
    } else {
      // Remove unchecked item from checkList
      setFilterTitle('')
      setFilterValue('')
      setIsParamsRemoved(true)

      deleteCategoriesQueryParams(true, value)
    }
  }

  return (
    <div className="collectionFilterItem">
      <div className="position-relative" id="taxonomies" ref={wrapperRef}>
        <div
          className={collectionFilterToggle}
          onClick={() => setIsToggled(!isToggled)}
        >
          <span className="label-wrapper">
            {collectionFilters?.title ? collectionFilters?.title : 'Loading...'}
          </span>
        </div>
        <div className={collapsedPanelClass}>
          <ul className="cost-list">
            {filtersToRender &&
              filtersToRender.map((item) => {
                return (
                  <li className="cost-item" key={Math.random()}>
                    <span className="filterCosts">
                      <label>
                        <input
                          checked={
                            availabilityValue === item.value ||
                            [...new Set([...categoryValue])]?.includes(
                              item.value,
                            )
                          }
                          disabled={isLoading}
                          name={item.title}
                          onChange={(event) => {
                            if (availabilityValue) {
                              handleSelectAvailability(event)
                            } else {
                              handleSelectCategory(event)
                              setIsParamsRemoved(false)
                            }
                          }}
                          type="checkbox"
                          value={item.value}
                        />
                        {item.title}
                      </label>
                    </span>
                  </li>
                )
              })}
          </ul>
        </div>
      </div>
    </div>
  )
}

export default React.memo(CollectionFilterCheckBox)

CollectionFilterCheckBox.propTypes = {
  activeFilters: PropTypes.array,
  availabilityValue: PropTypes.array,
  categoryValue: PropTypes.array,
  collectionFilters: PropTypes.object,
  isBrigades: PropTypes.bool,
  isLoading: PropTypes.bool,
  setFilterTitle: PropTypes.func,
  setFilterValue: PropTypes.func,
  setIsParamsRemoved: PropTypes.func,
}
