import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react'
import PropTypes from 'prop-types'
import { CircularProgress } from '@mui/material'
import RedeemCharity from './RedeemCharity'

const CharitiesMySaved = forwardRef(({
  availablePointsPath,
  currentUser,
  newUserSessionPath,
  redeemCharityPath,
  redeemUrl,
  savedCharitiesPath,
  t
}, ref) => {
  const [approvedCharities, setApprovedCharities] = useState([])
  const [loading, setLoading] = useState(false)
  const props = {
    availablePointsPath,
    currentUser,
    newUserSessionPath,
    redeemCharityPath,
    redeemUrl,
    savedCharitiesPath,
    t
  }

  useImperativeHandle(ref, () => ({
    loadJSON
  }))

  useEffect(() => {
    loadJSON()
  }, [savedCharitiesPath])

  const loadJSON = () => {
    setLoading(true)
    fetch(savedCharitiesPath)
      .then(response => response.json())
      .then(data => {
        setApprovedCharities(data.approved_charities)
        setLoading(false)
      })
      .catch(() => {
        window.alert(t['tcred.charities.fetch_failed'])
        setLoading(false)
      })
  }
  const removeCharity = (e, destroyUrl) => {
    e.preventDefault()
    if (window.confirm(t['tcnext.profile.confirm_remove_charity'])) {
      fetch(destroyUrl, {
        method: 'DELETE'
      })
        .then(() => {
          loadJSON()
        })
        .catch(() => {
          window.alert(t['profile.failed_remove_charity'])
        })
    }
  }

  const mapCharities = () => {
    return (
      <React.Fragment>
        {approvedCharities.map(charity => (
          <tr className="position-relative d-lg-table-row d-flex" key={charity.id}>
            <td className="d-lg-flex d-table-cell flex-wrap">
              {charity.current_state === 'approved' && (
                <span className="d-lg-inline d-block">
                  <RedeemCharity charity={charity} parentsProps={props} updateSaveOrg={loadJSON} />
                </span>
              )}
              <span className="d-lg-inline d-block">{charity.name}</span>
              <span className="d-lg-block d-inline fw-normal grey-text w-100">
                {charity.region_code ? `${charity.city}, ${charity.region_code}` : charity.city}
              </span>
            </td>
            <td className="align-items-center d-flex d-lg-table-cell text-end">
              {charity.edit_url && (
                <>
                  <span className="d-inline fw-normal grey-text w-100 me-3"><i>{charity.current_state}</i></span>
                  <a className="edit-icon fas fa-pencil-alt me-5" href={charity.edit_url} />
                </>
              )}
              <a className="delete-icon icon-times" href="#" onClick={(e) => removeCharity(e, charity.destroy_url)} />
            </td>
          </tr>
        ))}
      </React.Fragment>
    )
  }

  return (
    !loading && (
      <div>
        {approvedCharities.length > 0 ? (
          <>
            <h5><b>{t['tcred.my_saved_organizations']}</b></h5>
            <div className="manageCharity_results">
              <div className="tableComponent tableComponent_oneColumnMobile">
                <table className="tableComponent_table tableComponent_withoutHeadline">
                  <tbody>{mapCharities()}</tbody>
                </table>
              </div>
              {loading && <div className="loader"><CircularProgress size={40} /></div>}
            </div>
          </>
        ) : (
          <p>{t['tcred.profile.points.dont_have_saved_organizations']}
            <a href={redeemUrl}><b>{t['tcred.profile.view_all_charities']}</b></a>
          </p>
        )}
      </div>
    )
  )
})

CharitiesMySaved.displayName = 'CharitiesMySaved'

CharitiesMySaved.propTypes = {
  availablePointsPath: PropTypes.string,
  currentUser: PropTypes.object,
  newUserSessionPath: PropTypes.string,
  redeemCharityPath: PropTypes.string,
  redeemUrl: PropTypes.string,
  savedCharitiesPath: PropTypes.string,
  t: PropTypes.object
}

export default CharitiesMySaved
