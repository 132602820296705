import React from 'react'
import PropTypes from 'prop-types'

const LabelButton = ({ isLoading, handleLabelRequest, data, t }) => (
  <button
    className="activeCollection_requestLabelLink buttonComponent--small me-5"
    disabled={isLoading}
    key={data.id}
    onClick={() => handleLabelRequest(data)}
  >
    {isLoading ? t['submit.clicked.working'] : data.label}
  </button>
)

LabelButton.propTypes = {
  data: PropTypes.object.isRequired,
  handleLabelRequest: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  t: PropTypes.object.isRequired,
}

export default LabelButton
