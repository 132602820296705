import React from 'react'
import PropTypes from 'prop-types'
import { QuickViewModal } from '@tc-packages/tc-common'
const TCQuickViewModalWrapper = ({ config }) => (
  <QuickViewModal config={config} />
)

TCQuickViewModalWrapper.propTypes = {
  config: PropTypes.object.isRequired,
}

export default TCQuickViewModalWrapper
