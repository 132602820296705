import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Modal } from '@mui/material'
import UserAvailablePoints from './UserAvailablePoints'
import FlashMessage from './FlashMessage'

const RedeemPrize = ({
  availablePointsPath,
  currentUser,
  newUserSessionPath,
  prizeId,
  prizeName,
  prizeCost,
  redeemPrizePath,
  redeemLinkClasses,
  t
}) => {
  const [openModal, setOpenModal] = useState(false)
  const [hasError, setHasError] = useState(null)
  const [isSuccess, setIsSuccess] = useState(false)
  const [disabled, setDisabled] = useState(false)

  const handleOpenModal = (e) => {
    e.preventDefault()
    setOpenModal(true)
  }

  const handleCloseModal = () => {
    setOpenModal(false)
    setHasError(null)
  }

  const submitRedeem = async (e) => {
    e.preventDefault()
    if (disabled) return

    setDisabled(true)
    try {
      const response = await fetch(redeemPrizePath, {
        body: JSON.stringify({ prize_id: prizeId }),
        headers: {
          'Content-Type': 'application/json',
        },
        method: 'POST'
      })
      const data = await response.json()
      if (response.ok) {
        handleCloseModal()
        setIsSuccess(true)
        document.getElementById('userPoints').dispatchEvent(new Event('change'))
      } else {
        throw new Error(data && data.errors ? data.errors : 'Unknown error')
      }
    } catch (error) {
      setHasError(error.message)
      setDisabled(false)
    }
  }

  return (
    <React.Fragment>
      {currentUser ? (
        <a className={redeemLinkClasses} href="#" onClick={handleOpenModal}>
          {t['tcnext.redeem']}
        </a>
      ) : (
        <a className={`${redeemLinkClasses} ms-4`} href={newUserSessionPath}>
          {t['tcnext.redeem']}
        </a>
      )}
      <Modal
        aria-labelledby="redeem-prize-modal"
        className="modal-material"
        onClose={handleCloseModal}
        open={openModal}
      >
        <div className="modal-dialog modal-dialog-centered">
          <div className="modal-content">
            <div className="modal-body">
              <button className="closeModalButton" onClick={handleCloseModal}>
                <span>+</span>
              </button>
              <div className="pointModal_headline">
                <div className="pointModal_title">
                  {t['tcred.current_points_balance']}
                </div>
                <div className="pointModal_points">
                  <UserAvailablePoints
                    availablePointsPath={availablePointsPath}
                    failedToFetchPoints={t['redeem.failed_to_fetch_available_points']}
                  />
                </div>
              </div>
              <div className="pointModal_form">
                <form onSubmit={submitRedeem}>
                  <fieldset className="bootstrapForm_fieldset text-center">
                    {t['tcred.you_are_redeeming']} <b>{prizeCost}</b> {t['tcred.points_for']} <b>{prizeName}</b>
                    {hasError && <div className="pointModal_error text-start">{hasError}</div>}
                  </fieldset>
                  <div className="bootstrapForm_actions">
                    <div className="col-md-8 mx-auto">
                      <input className="buttonComponent--primary w-100" disabled={disabled} type="submit" value={t['tcred.redeem_points']} />
                      <button className="btn pointModal_cancel-btn m-0 mt-2 mt-lg-4" onClick={handleCloseModal}>
                        {t['tcred.cancel_this_gift']}
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </Modal>
      <FlashMessage handleClose={() => setIsSuccess(false)}
        isSuccess={isSuccess}
        message={t['tcnext.cart.checkout_success']}
        status={'isSuccess'}
      />
    </React.Fragment>
  )
}

RedeemPrize.propTypes = {
  availablePointsPath: PropTypes.string,
  currentUser: PropTypes.object,
  newUserSessionPath: PropTypes.string,
  prizeCost: PropTypes.number.isRequired,
  prizeId: PropTypes.number.isRequired,
  prizeName: PropTypes.string.isRequired,
  redeemLinkClasses: PropTypes.string,
  redeemPrizePath: PropTypes.string.isRequired,
  t: PropTypes.object.isRequired
}

export default RedeemPrize
