import PropTypes from 'prop-types'
import React from 'react'
import MoreButton from './MoreButton'
import ProgressBar from './ProgressBar'
import Viewing from './Viewing'

const LoadMore = ({ displayedItemsNumber, isLoading, loadMoreProducts, pagination, t }) => {
  return (
    <div style={{ textAlign: 'center' }}>
      {pagination.total_count > 0 ? (
        <>
          <Viewing displayedItemsNumber={displayedItemsNumber} pagination={pagination} t={t} />
          {pagination.total_count <= 24 || pagination.page === pagination.total_pages ? null : (
            <>
              <ProgressBar displayedItemsNumber={displayedItemsNumber} pagination={pagination} />
              <MoreButton isLoading={isLoading} loadMoreProducts={loadMoreProducts} pagination={pagination} t={t} />
            </>
          )}
        </>
      ) : null}
    </div>
  )
}

export default LoadMore

LoadMore.propTypes = {
  displayedItemsNumber: PropTypes.number,
  isLoading: PropTypes.bool,
  loadMoreProducts: PropTypes.func,
  pagination: PropTypes.object,
  t: PropTypes.object
}
