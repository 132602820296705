import { PropTypes } from 'prop-types'
import React from 'react'
import { Snackbar, Fade, IconButton } from '@mui/material'
import { CloseOutlined } from '@mui/icons-material'
export default class FlashMessage extends React.Component {
  render() {
    return (
      <Snackbar
        action={
          <React.Fragment>
            <IconButton
              aria-label="close"
              color="inherit"
              onClick={this.props.handleClose}
            >
              <CloseOutlined style={{ fontSize: 20 }} />
            </IconButton>
          </React.Fragment>
        }
        anchorOrigin={{
          horizontal: 'right',
          vertical: 'top',
        }}
        autoHideDuration={6000}
        className={this.props.status}
        key={Fade.name}
        message={this.props.message}
        onClose={this.props.handleClose}
        open={this.props.isSuccess}
        TransitionComponent={Fade}
      />
    )
  }
}

FlashMessage.propTypes = {
  handleClose: PropTypes.func,
  isSuccess: PropTypes.bool,
  message: PropTypes.string,
  status: PropTypes.string
}
