import React, { useState, useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const ImageTextScroll = ({ sections, uniqueId, additionalClass }) => {
  const [activeSectionId, setActiveSectionId] = useState(sections[0].id)
  const [activeHeading, setActiveHeading] = useState(sections[0].heading || '')
  const [activeImageIndex, setActiveImageIndex] = useState(0)
  const [scrollBlur, setScrollBlur] = useState({
    bottom: true,
    top: false,
  })
  const observer = useRef(null)
  const sectionRefs = useRef(sections.map(() => React.createRef()))
  const textContainerRef = useRef(null)

  const updateActiveSection = (entry) => {
    const currentSectionId = Number(entry.target.getAttribute('data-id'))
    const currentSection = sections.find(
      (section) => section.id === currentSectionId,
    )
    if (!currentSection) return

    const currentTextIndex = currentSection.texts.findIndex(
      (text) => text === entry.target.textContent,
    )
    setActiveSectionId(currentSectionId)
    setActiveImageIndex(currentTextIndex !== -1 ? currentTextIndex : 0)

    // Update the heading only if the current section has a heading
    if (currentSection.heading) {
      setActiveHeading(currentSection.heading)
    }
  }

  useEffect(() => {
    // Flag to ignore initial intersection observer calls
    let ignoreInitialObservations = true

    observer.current = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting && !ignoreInitialObservations) {
            updateActiveSection(entry)
          }
        })
      },
      {
        root: textContainerRef.current,
        threshold: 0.5,
      },
    )

    sectionRefs.current.forEach((ref) => {
      if (ref.current) {
        observer.current.observe(ref.current)
      }
    })

    // Allow intersection observer to update states after initial render
    setTimeout(() => {
      ignoreInitialObservations = false
    }, 1000)

    return () => {
      sectionRefs.current.forEach((ref) => {
        if (ref.current) {
          observer.current.unobserve(ref.current)
        }
      })
    }
  }, [sections])

  const updateTextContainerHeight = () => {
    const activeImageSelector = `.image-text-scroll-${uniqueId} .image-container img.active`
    const headingSelector = `.image-text-scroll-${uniqueId} .text-column .section-heading`
    const textContainerSelector = `.image-text-scroll-${uniqueId} .text-container`

    const activeImage = document.querySelector(activeImageSelector)
    const heading = document.querySelector(headingSelector)
    const textContainer = document.querySelector(textContainerSelector)

    if (activeImage && textContainerRef.current) {
      const imageHeight = activeImage.offsetHeight
      let headingHeight = 0
      let textContainerMarginTop = 0

      if (heading) {
        headingHeight = heading.offsetHeight
      }

      if (textContainer) {
        const style = window.getComputedStyle(textContainer)
        textContainerMarginTop = parseInt(style.marginTop, 10)
      }

      const totalHeight = imageHeight - (headingHeight + textContainerMarginTop)
      textContainerRef.current.style.height = `${totalHeight}px`
    }
  }

  // Preload images
  useEffect(() => {
    sections.forEach((section) => {
      section.images.forEach((image) => {
        const img = new Image()
        img.src = image
      })
    })
  }, [sections])

  useEffect(() => {
    window.addEventListener('resize', updateTextContainerHeight)
    return () => window.removeEventListener('resize', updateTextContainerHeight)
  }, [])

  // Update text container height when active image changes
  useEffect(() => {
    updateTextContainerHeight()
  }, [activeSectionId, activeImageIndex])

  const scrollToTop = () => {
    if (document.readyState === 'complete') {
      const textContainer = textContainerRef.current
      if (textContainer) {
        textContainer.scrollTop = 0
      }
    } else {
      window.addEventListener('load', () => {
        const textContainer = textContainerRef.current
        if (textContainer) {
          textContainer.scrollTop = 0
        }
      })
    }
  }

  useEffect(() => {
    scrollToTop()
  }, [sections])

  const onScroll = () => {
    if (!textContainerRef.current) return
    const { scrollTop, clientHeight, scrollHeight } = textContainerRef.current

    setScrollBlur({
      bottom: scrollHeight - (scrollTop + clientHeight) > 1,
      top: scrollTop > 0,
    })
  }

  return (
    <div className={`image-text-scroll-${uniqueId}`}>
      <div className="row justify-content-center">
        <div className="col-xs-12 col-xl-6 col-xxxl-5">
          <div className="image-container">
            {sections.map((section) =>
              section.images.map((image, imageIndex) => (
                <img
                  alt={`Section ${section.id} Image ${imageIndex}`}
                  className={`fade-image ${activeSectionId === section.id && activeImageIndex === imageIndex ? 'active' : ''}`}
                  /* eslint-disable-next-line react/no-array-index-key */
                  key={`${section.id}-${imageIndex}`}
                  onLoad={updateTextContainerHeight}
                  src={image}
                />
              )),
            )}
          </div>
        </div>
        <div className="col-xs-12 col-xl-6 col-xxxl-5 text-column">
          <h3
            className={`section-heading ${sections.some((section) => section.uppercase) ? 'text-uppercase' : ''}`}
          >
            {activeHeading}
          </h3>
          <div
            className={`text-container-wrap ${scrollBlur.top && 'blur-top'} ${scrollBlur.bottom && 'blur-bottom'}`}
          >
            <div
              className={`text-container ${additionalClass}`}
              onScroll={onScroll}
              ref={textContainerRef}
            >
              {sections.map((section) => (
                <div
                  className="section"
                  data-id={section.id}
                  key={section.id}
                  ref={sectionRefs.current[section.id - 1]}
                >
                  {section.heading && (
                    <h3
                      className={`section-heading ${section.uppercase ? 'text-uppercase' : ''} d-block d-xl-none`}
                    >
                      {section.heading}
                    </h3>
                  )}
                  {section.texts.map((text) => (
                    <p className="section-text" key={text}>
                      {text}
                    </p>
                  ))}
                  {section.cta_text && section.cta_link && (
                    <a
                      className="buttonComponent--primary"
                      href={section.cta_link}
                    >
                      {section.cta_text}
                    </a>
                  )}
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

ImageTextScroll.propTypes = {
  additionalClass: PropTypes.string,
  sections: PropTypes.arrayOf(
    PropTypes.shape({
      cta_link: PropTypes.string,
      cta_text: PropTypes.string,
      heading: PropTypes.string,
      id: PropTypes.number.isRequired,
      images: PropTypes.arrayOf(PropTypes.string).isRequired,
      texts: PropTypes.arrayOf(PropTypes.string).isRequired,
      uppercase: PropTypes.bool,
    }),
  ).isRequired,
  uniqueId: PropTypes.string.isRequired,
}

export default ImageTextScroll
