import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import useApiRequest from '../hooks/useApiRequest'

const BonusHistory = ({ bonusesPath, t }) => {
  const [bonuses, setBonuses] = useState(null)
  const { sendRequest } = useApiRequest()

  useEffect(() => {
    const fetchBonuses = async () => {
      try {
        const data = await sendRequest(bonusesPath)
        setBonuses(data.collection_adjustments)
      } catch (error) {
        window.alert(t['tcnext.failed_to_fetch_bonus_history'])
      }
    }

    fetchBonuses()
  }, [bonusesPath, t, sendRequest])

  const renderBonuses = (bonuses) => (
    <div className="tableComponent w-100 mt-4">
      <table className="tableComponent_table">
        <thead>
          <tr>
            <th>{t['tcnext.date_created']}</th>
            <th>{t['tcnext.bonus_reason']}</th>
            <th>{t['tcnext.points_earned']}</th>
          </tr>
        </thead>
        <tbody>
          {bonuses.map((bonus) => (
            <tr key={bonus.id}>
              <td>{bonus.created_at.slice(0, 10)}</td>
              <td>{bonus.adjusted_because}</td>
              <td>{bonus.bonus_points}</td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )

  return (
    <div>{bonuses ? renderBonuses(bonuses) : `${t['tcnext.loading']}...`}</div>
  )
}

BonusHistory.propTypes = {
  bonusesPath: PropTypes.string.isRequired,
  t: PropTypes.object.isRequired,
}

export default BonusHistory
