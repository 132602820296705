import React from 'react'
import PropTypes from 'prop-types'

const LabelNote = ({
  labelsLength,
  brigadeLabelLimitIsReached,
  collectionLabelLimitIsReached,
  t,
}) => {
  let note
  if (labelsLength > 0) {
    note = t['tcred.profile.my_programs.hint_for_having_labels']
  } else if (
    brigadeLabelLimitIsReached &&
    (collectionLabelLimitIsReached || labelsLength === 0)
  ) {
    note =
      t[
        'tcred.profile.my_programs.label_limit_reached.brigade.short_description'
      ]
  } else {
    note = t['tcred.profile.my_programs.hint_for_no_labels']
  }
  return <p className="labelNote">{note}</p>
}

LabelNote.propTypes = {
  brigadeLabelLimitIsReached: PropTypes.bool.isRequired,
  collectionLabelLimitIsReached: PropTypes.bool.isRequired,
  labelsLength: PropTypes.number.isRequired,
  t: PropTypes.object.isRequired,
}

export default LabelNote
