import { PropTypes } from 'prop-types'
import React from 'react'

export default class UserAvailablePoints extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      availablePoints: '',
    }
  }

  componentDidMount() {
    const userPoints = document.getElementById('userPoints')
    this.getAvailablePoints()
    if (userPoints) {
      userPoints.addEventListener('change', this.getAvailablePoints)
    }
  }

  componentWillUnmount() {
    const userPoints = document.getElementById('userPoints')
    if (userPoints) {
      userPoints.removeEventListener('change', this.getAvailablePoints)
    }
  }

  getAvailablePoints = () => {
    $.getJSON(this.props.availablePointsPath)
      .done((response) => {
        this.setState({
          availablePoints: response.available_points,
        })
      })
      .fail((error) => {
        if (error.status == 401) {
          window.location.href = '/account/sign_in'
        } else {
          window.alert(this.props.failedToFetchPoints)
        }
      })
  }

  render() {
    return this.state.availablePoints.toLocaleString()
  }
}

UserAvailablePoints.propTypes = {
  availablePointsPath: PropTypes.string,
  failedToFetchPoints: PropTypes.string,
}
