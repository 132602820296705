export const setQueryParams = (availability, price, keyword, category) => {
  const url = new URL(window.location)

  if (availability && availability.length > 0)
    url.searchParams.set('availabilities[]', availability)
  if (price) url.searchParams.set('price', price)
  if (keyword) url.searchParams.set('query', keyword)
  if (category.length > 0) {
    category.map((item) => {
      if (url.search.includes(`categories%5B%5D=${item}`)) {
        return null
      } else {
        url.searchParams.append('categories[]', item)
      }
    })
  }

  if (availability || price || keyword || category)
    window.history.pushState({}, '', url)
}

export const deleteQueryParams = (
  isKeywordParamsRemoved,
  isAvailabilityParamsRemoved,
  isCategoryParamsRemoved,
  isPriceParamsRemoved,
) => {
  const url = new URL(window.location)

  if (isCategoryParamsRemoved) url.searchParams.delete('categories[]')
  if (isAvailabilityParamsRemoved) url.searchParams.delete('availabilities[]')
  if (isPriceParamsRemoved) url.searchParams.delete('price')
  if (isKeywordParamsRemoved) url.searchParams.delete('query')

  if (
    isKeywordParamsRemoved ||
    isAvailabilityParamsRemoved ||
    isPriceParamsRemoved ||
    isCategoryParamsRemoved
  )
    window.history.pushState({}, '', url)
}

export const deleteCategoriesQueryParams = (
  isCategoryParamsRemoved,
  categoryValue,
) => {
  const url = new URL(window.location)

  if (isCategoryParamsRemoved)
    url.searchParams.delete('categories[]', categoryValue)

  if (isCategoryParamsRemoved) window.history.pushState({}, '', url)
}

export const getQueryParams = () => {
  const url = new URL(window.location)

  const keyword = url.searchParams.get('query') || ''
  const price = url.searchParams.get('price') || ''
  const availability = url.searchParams.get('availabilities[]') || ''
  const category = url.searchParams.getAll('categories[]') || ''

  return { availability, category, keyword, price }
}
