import React, { useEffect, useRef, useState, useCallback } from 'react'
import PropTypes from 'prop-types'

const HistoryTabs = ({ tabs }) => {
  const [isSticky, setIsSticky] = useState(false)
  const tabsRef = useRef(null)

  const isActiveTab = useCallback((tabUrl) => {
    return window.location.pathname.includes(tabUrl)
  }, [])

  const centerActiveTab = useCallback(() => {
    requestAnimationFrame(() => {
      const activeTab = document.querySelector('.history-tabs .tab-link.active')
      if (activeTab && tabsRef.current) {
        const tabLeft = activeTab.offsetLeft
        const tabWidth = activeTab.offsetWidth
        const containerWidth = tabsRef.current.offsetWidth
        const scrollLeft = tabLeft - containerWidth / 2 + tabWidth / 2
        tabsRef.current.scrollLeft = scrollLeft
      }
    })
  }, [])

  const updateStickyState = useCallback(() => {
    const tcNavbar = document.getElementById('tcNavbar')
    if (!tcNavbar) {
      console.error('tcNavbar element not found.')
      return
    }
    const isNavbarFixed =
      tcNavbar.classList.contains('fixed') &&
      tcNavbar.classList.contains('searchHidden')
    setIsSticky(isNavbarFixed)

    const historyTabsContainer = document.querySelector(
      '.history-tabs-container',
    )
    if (historyTabsContainer && historyTabsContainer.parentNode) {
      historyTabsContainer.parentNode.classList.toggle(
        'sticky-container',
        isNavbarFixed,
      )
    }
  }, [])

  useEffect(() => {
    updateStickyState()

    const observer = new MutationObserver(updateStickyState)
    const tcNavbar = document.getElementById('tcNavbar')
    if (tcNavbar) {
      observer.observe(tcNavbar, {
        attributeFilter: ['class'],
        attributes: true,
      })
    }

    // Center the active tab on mount and on resize
    centerActiveTab()
    window.addEventListener('resize', centerActiveTab)

    return () => {
      observer.disconnect()
      window.removeEventListener('resize', centerActiveTab)
    }
  }, [centerActiveTab, updateStickyState])

  return (
    <div className={`history-tabs-container ${isSticky ? 'sticky' : ''}`}>
      <div className="container">
        <div className="row">
          <div className="col-lg-12">
            <nav className="history-tabs" ref={tabsRef}>
              {tabs.map((tab) => (
                <a
                  className={`tab-link ${isActiveTab(tab.url) ? 'active' : ''}`}
                  href={tab.url}
                  key={tab.key}
                  onClick={centerActiveTab}
                >
                  {tab.label}
                </a>
              ))}
            </nav>
          </div>
        </div>
      </div>
    </div>
  )
}

HistoryTabs.propTypes = {
  tabs: PropTypes.arrayOf(
    PropTypes.shape({
      key: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
}

export default HistoryTabs
