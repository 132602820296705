import React, { useState, useCallback } from 'react'
import PropTypes from 'prop-types'
import useApiRequest from '../hooks/useApiRequest'
import BonusHistorySection from './BonusHistorySection'
import RemoveBrigadeButton from './RemoveBrigadeButton'
import LabelButtons from './LabelButtons'
import WtnSelector from './WtnSelector'
import ShowMoreButton from './ShowMoreButton'
import LabelNote from './LabelNote'
import BrigadeLabelRequestModalWrapper from '../Modals/BrigadeLabelRequestModalWrapper'
import PointsSection from './PointsSection'
import BrigadeHeader from './BrigadeHeader'
import LabelTable from './LabelTable'

const Brigade = ({
  bonusesPath,
  brigadeDescription,
  brigadeLabelLimitIsReached,
  brigadePath,
  clearLabelMessagePath,
  collectionLabelLimitIsReached,
  commonPointsAllocation,
  downloadResourcesPath,
  dutyCareLink,
  hasBonus,
  id,
  imageUrl,
  labels,
  leaveBrigadePath,
  name,
  pointsPerShipment,
  requestLabelPath,
  shipmentMinWeightUnits,
  shipmentMinWeightValue,
  shipmentsPath,
  shippingModelData,
  t,
  wtnIds,
  wtnPdfPath,
}) => {
  const [isShowingBonusHistory, setIsShowingBonusHistory] = useState(false)
  const [isShowingLabels, setIsShowingLabels] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState({
    name: '',
    text: '',
    subheader: '',
    type: 'success',
  })

  const { isLoading, sendRequest } = useApiRequest()

  const toggleBonusHistory = useCallback((e) => {
    e.preventDefault()
    setIsShowingBonusHistory((prevState) => !prevState)
  }, [])

  const toggleLabel = useCallback((e) => {
    e.preventDefault()
    setIsShowingLabels((prevState) => !prevState)
  }, [])

  const requestLabel = async (requestPath, shippingModelId, successMessage) => {
    try {
      await sendRequest(requestPath, 'POST', {
        label_request: { shipping_model_id: shippingModelId },
      })
      setModalContent({
        name,
        subheader: t['flash.users.label_request_received_subheader'],
        text: successMessage,
        type: 'success',
      })
      setIsModalOpen(true)
    } catch (error) {
      setModalContent({
        name,
        subheader: '',
        text: t['tcred.label.request.failed.body'],
        type: 'error',
      })
      setIsModalOpen(true)
    }
  }

  const handleLabelRequest = (data) => {
    if (data.confirmation_message) {
      if (window.confirm(data.confirmation_message)) {
        requestLabel(requestLabelPath, data.id, data.success_message)
      }
    } else {
      requestLabel(requestLabelPath, data.id, data.success_message)
    }
  }

  const renderDownloadLabelButton = (labelUrl) => (
    <a
      className="shipmentHistory_requestLabel"
      href={labelUrl}
      rel="noreferrer"
      target="_blank"
    >
      {t['tcnext.download_label']}
    </a>
  )

  return (
    <div>
      <RemoveBrigadeButton path={leaveBrigadePath} t={t} />

      <div className="activeCollection_lable mobile active">
        <span>{t['tcred.collection.active']}</span>
      </div>

      <div className="activeCollection_mainBlock toggledBody">
        <BrigadeHeader
          brigadeDescription={brigadeDescription}
          brigadePath={brigadePath}
          name={name}
          t={t}
        />

        <PointsSection
          commonPointsAllocation={commonPointsAllocation}
          pointsPerShipment={pointsPerShipment}
          shipmentMinWeightUnits={shipmentMinWeightUnits}
          shipmentMinWeightValue={shipmentMinWeightValue}
          t={t}
        />

        <LabelNote
          brigadeLabelLimitIsReached={brigadeLabelLimitIsReached}
          collectionLabelLimitIsReached={collectionLabelLimitIsReached}
          labelsLength={labels.length}
          t={t}
        />

        <LabelButtons
          brigadeLabelLimitIsReached={brigadeLabelLimitIsReached}
          collectionLabelLimitIsReached={collectionLabelLimitIsReached}
          downloadResourcesPath={downloadResourcesPath}
          handleLabelRequest={handleLabelRequest}
          isLoading={isLoading}
          isShowingLabels={isShowingLabels}
          labels={labels}
          renderDownloadLabelButton={renderDownloadLabelButton}
          shipmentsPath={shipmentsPath}
          shippingModelData={shippingModelData}
          t={t}
          toggleLabel={toggleLabel}
        />

        <LabelTable
          brigadeLabelLimitIsReached={brigadeLabelLimitIsReached}
          collectionLabelLimitIsReached={collectionLabelLimitIsReached}
          handleLabelRequest={handleLabelRequest}
          isLoading={isLoading}
          isShowingLabels={isShowingLabels}
          labels={labels}
          renderDownloadLabelButton={renderDownloadLabelButton}
          shippingModelData={shippingModelData}
          t={t}
        />

        <WtnSelector
          dutyCareLink={dutyCareLink}
          id={id}
          t={t}
          wtnIds={wtnIds}
          wtnPdfPath={wtnPdfPath}
        />

        <BonusHistorySection
          bonusesPath={bonusesPath}
          hasBonus={hasBonus}
          isShowingBonusHistory={isShowingBonusHistory}
          t={t}
          toggleBonusHistory={toggleBonusHistory}
        />
      </div>

      <ShowMoreButton t={t} />

      <BrigadeLabelRequestModalWrapper
        imageUrl={imageUrl}
        isOpen={isModalOpen}
        name={name}
        onClose={() => setIsModalOpen(false)}
        postUrl={clearLabelMessagePath}
        subheader={modalContent.subheader}
        text={modalContent.text}
        type={modalContent.type}
      />
    </div>
  )
}

Brigade.propTypes = {
  bonusesPath: PropTypes.string.isRequired,
  brigadeDescription: PropTypes.string,
  brigadeLabelLimitIsReached: PropTypes.bool,
  brigadePath: PropTypes.string,
  clearLabelMessagePath: PropTypes.string.isRequired,
  collectionLabelLimitIsReached: PropTypes.bool,
  commonPointsAllocation: PropTypes.bool,
  downloadResourcesPath: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
  ]),
  dutyCareLink: PropTypes.string,
  hasBonus: PropTypes.bool,
  hasLastShipment: PropTypes.bool,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  imageUrl: PropTypes.string,
  labels: PropTypes.array,
  leaveBrigadePath: PropTypes.string,
  name: PropTypes.string,
  pointsPerShipment: PropTypes.string,
  requestLabelPath: PropTypes.string,
  shipmentMinWeightUnits: PropTypes.string,
  shipmentMinWeightValue: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  shipmentsPath: PropTypes.string,
  shippingModelData: PropTypes.array,
  t: PropTypes.object.isRequired,
  wtnIds: PropTypes.array,
  wtnPdfPath: PropTypes.string,
}

export default Brigade
