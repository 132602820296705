import React from 'react'
import PropTypes from 'prop-types'
import BonusHistory from './BonusHistory'

const BonusHistorySection = ({
  hasBonus,
  isShowingBonusHistory,
  toggleBonusHistory,
  bonusesPath,
  t,
}) => (
  <div className="activeCollection_buttonWrapper d-block">
    {!hasBonus ? null : (
      <div className="activeCollection_bonuses">
        <a
          className="activeCollection_historyLink readMoreLink"
          href="#"
          onClick={toggleBonusHistory}
        >
          {t['tcnext.see_bonus_history']}
        </a>
      </div>
    )}
    {!isShowingBonusHistory ? null : (
      <BonusHistory bonusesPath={bonusesPath} t={t} />
    )}
  </div>
)

BonusHistorySection.propTypes = {
  bonusesPath: PropTypes.string.isRequired,
  hasBonus: PropTypes.bool.isRequired,
  isShowingBonusHistory: PropTypes.bool.isRequired,
  t: PropTypes.object.isRequired,
  toggleBonusHistory: PropTypes.func.isRequired,
}

export default BonusHistorySection
