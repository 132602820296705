import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

function CurbsideSearch({ curbsides, t }) {
  const [zip, setZip] = useState('')
  const [foundCurbsides, setFoundCurbsides] = useState([])
  const [searchPerformed, setSearchPerformed] = useState(false)
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(null)

  useEffect(() => {
    setError(null)

    const formWrapper = document.querySelector('.hbspt-form__wrapper')
    if (formWrapper) {
      if (searchPerformed && foundCurbsides.length === 0) {
        formWrapper.style.display = 'block'
      } else {
        formWrapper.style.display = 'none'
      }
    }
  }, [searchPerformed, foundCurbsides])

  const handleSearch = event => {
    event.preventDefault()
    setLoading(true)
    try {
      const matchedCurbsides = Object.values(curbsides).filter(curbside =>
        curbside.zip_codes.includes(zip)
      )
      setFoundCurbsides(matchedCurbsides)
      setSearchPerformed(true)
      setLoading(false)
    } catch (error) {
      setError(error.message)
      setLoading(false)
    }
  }

  const handleInputChange = event => {
    setZip(event.target.value)
    if (searchPerformed) setSearchPerformed(false)
  }

  return (
    <div>
      <form className="bootstrapForm_formGroup" onSubmit={handleSearch}>
        <div className="row">
          <div className="col-md-10">
            <div className="row">
              <div className="col-md-12">
                <div className="bootstrapForm_label">
                  <label htmlFor="zipInput">
                    <abbr title={t['tc_curbsides.required_label']}>*</abbr>
                    {t['tc_curbsides.search_label']}
                  </label>
                </div>
              </div>
              <div className="col-md-12">
                <div className="row">
                  <div className="col-md-6">
                    <div className="bootstrapForm_input mb-5">
                      <input
                        id="zipInput"
                        onChange={handleInputChange}
                        placeholder={t['tc_curbsides.search_placeholder']}
                        required
                        type="text"
                        value={zip}
                      />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <button className="buttonComponent--primary buttonComponent--medium"
                      disabled={loading}
                      type="submit"
                    >
                      {loading ? t['tc_curbsides.searching'] : t['tc_curbsides.search']}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      {loading && <p>{t['tc_curbsides.loading']}</p>}
      {error && <p>{error}</p>}
      {searchPerformed && foundCurbsides.length > 0 ? (
        <div>
          <div className="mb-5 composter__search-result"
            dangerouslySetInnerHTML={{__html: t['tc_curbsides.search_found']}}
          >
          </div>
          <div className="row g-0">
            {foundCurbsides.map(curbside => (
              <div className="col-md-6" key={curbside.name}>
                <div className="composter__card">
                  <h5 className="composter__name">{curbside.name}</h5>
                  <a className="buttonComponent--primary buttonComponent--small composter__link"
                    href={curbside.url}
                  >{t['tc_curbsides.search_item_action']}
                  </a>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : searchPerformed ? (
        <div className="mb-5 composter__search-result">
          <div dangerouslySetInnerHTML={{__html: t['tc_curbsides.search_not_found'] }}></div>
        </div>
      ) : null}
    </div>
  )
}

CurbsideSearch.propTypes = {
  curbsides: PropTypes.objectOf(
    PropTypes.shape({
      name: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
      zip_codes: PropTypes.arrayOf(PropTypes.string).isRequired,
    })
  ).isRequired,
  t: PropTypes.object.isRequired,
}

export default CurbsideSearch
