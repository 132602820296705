import React, { useState } from 'react'
import PropTypes from 'prop-types'
import BonusHistory from './BonusHistory'
import RemoveBrigadeButton from './RemoveBrigadeButton'
import WtnSelector from './WtnSelector'
import ShowMoreButton from './ShowMoreButton'
import { writeProgramHistory } from './utils/profileProgramUtils'

const DeletedBrigade = ({
  bonusesPath,
  brigadePath,
  dutyCareLink,
  hasBonus,
  hasLastShipment,
  id,
  leaveBrigadePath,
  name,
  shipmentsPath,
  t,
  wtnIds,
  wtnPdfPath,
  years,
}) => {
  const [isShowingBonusHistory, setIsShowingBonusHistory] = useState(false)

  const toggleBonusHistory = (e) => {
    e.preventDefault()
    setIsShowingBonusHistory((prevState) => !prevState)
  }

  const renderBonusHistory = () => (
    <div>
      {!hasBonus ? null : (
        <div className="activeCollection_bonuses">
          <a
            className="activeCollection_historyLink"
            href="#"
            onClick={toggleBonusHistory}
          >
            {t['tcnext.see_bonus_history']}
          </a>
        </div>
      )}
      {!isShowingBonusHistory ? null : (
        <BonusHistory bonusesPath={bonusesPath} t={t} />
      )}
    </div>
  )

  return (
    <div>
      <RemoveBrigadeButton path={leaveBrigadePath} t={t} />
      <div className="deletedCollection_lable mobile inactive">
        <span>{t['tcred.collection.deleted']}</span>
      </div>

      <div className="deletedCollection_mainBlock toggledBody">
        <div className="deletedCollection_lable inactive">
          <span>{t['tcred.collection.deleted']}</span>
        </div>
        <div className="deletedCollection_headline">
          <h5 className="deletedCollection_title">{name}</h5>
          <a
            className="deletedCollection_titleLink readMoreLink"
            href={brigadePath}
          >
            {t['brigades.learn_more']}
          </a>
        </div>

        <div className="deletedBrigadeCollection_shipments">
          {!hasLastShipment ? null : (
            <p className="deletedBrigadeCollection_lastShipment">
              {t['tcnext.profile.received_shipment_with_stats']}
            </p>
          )}
        </div>

        <div className="deletedCollection_buttonWrapper">
          <div></div>
          <a
            className="deletedCollection_historyLink readMoreLink"
            href="#program-activity"
            onClick={() => writeProgramHistory(shipmentsPath)}
          >
            {t['tcnext.see_shipment_history']}
          </a>
        </div>

        {years && (
          <WtnSelector
            dutyCareLink={dutyCareLink}
            id={id}
            t={t}
            wtnIds={wtnIds}
            wtnPdfPath={wtnPdfPath}
            years={years}
          />
        )}

        {renderBonusHistory()}
      </div>
      <ShowMoreButton t={t} />
    </div>
  )
}

DeletedBrigade.propTypes = {
  bonusesPath: PropTypes.string.isRequired,
  brigadePath: PropTypes.string.isRequired,
  dutyCareLink: PropTypes.string,
  hasBonus: PropTypes.bool.isRequired,
  hasLastShipment: PropTypes.bool.isRequired,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  leaveBrigadePath: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  shipmentsPath: PropTypes.string.isRequired,
  t: PropTypes.object.isRequired,
  wtnIds: PropTypes.array,
  wtnPdfPath: PropTypes.string.isRequired,
  years: PropTypes.array.isRequired,
}

export default DeletedBrigade
