import React, { useState } from 'react'
import PropTypes from 'prop-types'

const DownloadResourcesDropdown = ({
  downloadResourcesPath,
  t,
  breadcrumbs,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)

  const toggleDropdown = () => {
    setIsExpanded(!isExpanded)
  }

  return (
    <div
      className={`downloadResources ${isExpanded ? 'expanded ' : ''}${breadcrumbs ? 'breadcrumbsDropdown' : ''}`}
    >
      <div className="downloadResources__button">
        <a
          className="downloadResources__buttonLink readMoreLink--download m-0"
          onClick={toggleDropdown}
        >
          {t || ''}
        </a>
      </div>
      <div className="downloadResources__dropdown">
        <div className="downloadResources__container">
          <div className="downloadResources__wrapper">
            <ul className="downloadResources__resourceList">
              {downloadResourcesPath.map((resource) => (
                <li
                  className="downloadResources__resourceItem"
                  key={resource.id || resource.url}
                >
                  <a
                    className="downloadResources__resourceLink"
                    href={resource.url}
                    rel="noopener noreferrer"
                    target="_blank"
                  >
                    {resource.label}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

DownloadResourcesDropdown.propTypes = {
  breadcrumbs: PropTypes.bool,
  downloadResourcesPath: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      label: PropTypes.string.isRequired,
      url: PropTypes.string.isRequired,
    }),
  ).isRequired,
  t: PropTypes.string,
}

export default DownloadResourcesDropdown
