import React, { useState } from 'react'
import PropTypes from 'prop-types'

const ShowMoreButton = ({ t }) => {
  const [isShowInformation, setIsShowInformation] = useState(false)

  const toggleText = () => {
    setIsShowInformation(!isShowInformation)
  }

  return (
    <span className="readMoreLink--down showMore_button" onClick={toggleText}>
      {isShowInformation
        ? t['tcred.hide_information']
        : t['tcred.view_information']}
    </span>
  )
}

ShowMoreButton.propTypes = {
  t: PropTypes.object.isRequired,
}

export default ShowMoreButton
