import React from 'react'
import { TcFooter } from '@tc-packages/tc-common'
import PropTypes from 'prop-types'

// TCFooterWrapper component wraps the TcFooter with additional props
const TCFooterWrapper = ({
  footerItems,
  acknowledgementOfCountry = '',
  isAcknowledgementOfCountryEnabled = false
}) => (
  <TcFooter
    acknowledgementOfCountry={acknowledgementOfCountry}
    footerItems={footerItems}
    isAcknowledgementOfCountryEnabled={isAcknowledgementOfCountryEnabled}
  />
)

TCFooterWrapper.propTypes = {
  acknowledgementOfCountry: PropTypes.string,
  footerItems: PropTypes.object.isRequired,
  isAcknowledgementOfCountryEnabled: PropTypes.bool
}

export default TCFooterWrapper
